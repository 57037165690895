<template>
    <div
        class="md:max-w-md max-w-container w-full m-auto pr-5 flex items-center justify-between sm:block sm:p-6 sm:pr-0 overflow-x-hidden rounded-lg"
        :class="noBg === false ? 'bg-grey2 p-12' : 'p-0 pt-5'"
    >
        <div class="flex items-center w-full" v-if="!loading">
            <!-- VERSION MOBILE-->
            <div class="hidden sm:block relative sm:max-w-full">
                <div class="font-black text-forest text-3xl mb-5 sm:max-w-sm sticky">
                    Un service client reconnu
                </div>
                <div>
                    <div class="flex overflow-hidden gap-x-8" v-swipe="onSwipe">
                        <div v-for="(review, index) in reviews" :key="review.id" class="min-w-[80%] max-w-full"
                             :id="'reviewMobile' + index">
                            <div class="mb-6 mt-2">
                                <div class="mb-4 min-h-[6rem]" v-if="reviews.length > 0">
                                    {{ ellipsis(review.text) }}
                                </div>

                                <div class="flex gap-x-2 justify-between items-center h-8" v-if="reviews.length > 0">
                                    <div class="w-2/4">
                                        <img :src="`//images-static.trustpilot.com/api/stars/${review.stars}/star.svg`"
                                             alt="note">
                                    </div>

                                    <div class="text-lg text-right font-medium w-2/4 flex justify-end pr-6">{{
                                            review.consumer.displayName.split(' ')[0] }} <br>
                                        {{ review.consumer.displayName.split(' ')[1] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col sm:pr-6">
                    <div class="flex gap-3 items-center mb-4 max-w-full">
                        <icons-star-outline class="w-5 h-5 text-forest"></icons-star-outline>
                        <div class="text-lg w-fit">
                            <strong>{{ business.score.stars }}/5</strong>
                            sur <a href="https://fr.trustpilot.com/review/valority.com" target="_blank" class="text-forest underline">{{ business.numberOfReviews.total }} avis TrustPilot</a>
                        </div>
                    </div>

                    <div class="flex gap-3 items-center mb-4">
                        <icons-user-group-outline class="w-5 h-5 text-forest"></icons-user-group-outline>
                        <div class="text-lg">Plus de 1200 investisseurs satisfaits</div>
                    </div>

                    <div class="flex gap-3 items-center">
                        <icons-check-circle-outline class="w-5 h-5 text-forest"></icons-check-circle-outline>
                        <div class="text-lg">Des clients qui nous gardent à vie.</div>
                    </div>
                </div>
            </div>

            <div class="sm:hidden flex gap-x-5 w-full">
                <a href="https://fr.trustpilot.com/review/valority.com" target="_blank"
                   class="h-full flex flex-col justify-center w-1/5">
                    <div class="text-[2rem] text-center font-bold">
                        Excellent
                    </div>

                    <div class="w-full h-8 mb-3">
                        <img :src="`//images-static.trustpilot.com/api/stars/${business.score.stars}/star.svg`"
                             alt="notation Truspilot"/>
                    </div>

                    <div class="text-sm text-center mb-2">
                        Sur la base de <span class="font-bold underline">{{
                            business.numberOfReviews.total
                        }} avis</span>
                    </div>

                    <div class="flex justify-center">
                        <img class="w-28" src="//images-static.trustpilot.com/api/logos/light-bg/logo.svg"
                             alt="Truspilot">
                    </div>
                </a>

                <!-- VERSION DESKTOP-->
                <div class="sm:hidden grid grid-cols-12 h-40 w-full">
                    <div class="col-span-1 flex items-center justify-center">
                        <div @click="move('left')"
                             class="border-2 rounded-full"
                             :class="positionSlider > 0 ? 'border-forest' : 'border-grey4'"
                        >
                            <icons-chevron-left-outline
                                class="w-5 h-5 cursor-pointer"
                                :class="positionSlider > 0 ? 'text-forest' : 'text-grey4'">
                            </icons-chevron-left-outline>
                        </div>
                    </div>

                    <div class="overflow-hidden flex col-span-10" ref="slider">
                        <div class="w-fit gap-x-7 flex">
                            <div v-for="(review, index) in reviews" :key="review.id" class="!w-[15rem]"
                                 :id="'review' + index">
                                <div class="flex justify-between items-center mb-4">
                                    <div class="w-32">
                                        <img :src="`//images-static.trustpilot.com/api/stars/${review.stars}/star.svg`"
                                             alt="note">
                                    </div>

                                    <div class="text-sm text-grey7">
                                        {{ timeAgo(review.createdAt) }}
                                    </div>
                                </div>

                                <div class="flex flex-col justify-between h-28">
                                    <div>
                                        <div class="text-sm font-bold mb-2 overflow-hidden text-ellipsis">
                                            {{ review.title }}
                                        </div>

                                        <div class="text-sm overflow-hidden whitespace-normal">
                                            {{ ellipsis(review.text) }}
                                        </div>
                                    </div>

                                    <div class="text-sm text-grey7">
                                        {{ review.consumer.displayName }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-span-1 flex items-center justify-center">
                        <div @click="move('right')"
                             class="border-2 rounded-full"
                             :class="positionSlider < reviews.length - 1 ? 'border-forest' : 'border-grey4'"
                        >
                            <icons-chevron-right-outline
                                class="w-5 h-5 cursor-pointer"
                                :class="positionSlider < reviews.length - 1 ? 'text-forest' : 'text-grey4'">
                            </icons-chevron-right-outline>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <c-loader v-else class="w-20 h-20"></c-loader>
    </div>
</template>

<script setup>
const {$axios} = useNuxtApp();
</script>

<script>
export default {
    name: "CardTrustpilot",
    props: {
        noBg: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            business: null,
            reviews: [],
            positionSlider: 0,
            loading: true,
        }
    },
    methods: {
        fetch() {
            let promises = [];

            promises.push(this.fetchFind());
            promises.push(this.fetchReviews());

            Promise.all(promises).finally(() => {
                this.loading = false;
            });
        },
        fetchFind() {
            return new Promise((resolve, reject) => {
                useNuxtApp().$axios.get('trustpilot/find').then(({data}) => {
                    this.business = data;
                    resolve();
                }).catch((reason) => {
                    console.error(reason);
                    reject();
                });
            });
        },
        fetchReviews() {
            return new Promise((resolve, reject) => {
                useNuxtApp().$axios.get('trustpilot/reviews').then(({data}) => {
                    this.reviews = data.reviews;
                    resolve();
                }).catch((reason) => {
                    console.error(reason);
                    reject();
                });
            });
        },
        timeAgo(d) {
            const date = useNuxtApp().$moment(d),
                now = useNuxtApp().$moment(),
                days = now.diff(date, "days"),
                weeks = now.diff(date, "weeks");

            if (weeks === 0 && days === 0) {
                return 'Aujourd\'hui';
            }

            let result = '';

            if (weeks !== 0) {
                result += `${weeks} semaine${weeks > 1 ? 's' : ''} `;
            } else if (days !== 0) {
                result += `${days} jour${days > 1 ? 's' : ''} `;
            }

            return result.trim();
        },
        ellipsis(text) {
            return ellipsisOn(text, 13);
        },
        move(direction) {
            if (direction === 'right' && this.positionSlider < this.reviews.length - 1) {
                this.positionSlider++;
            } else if (direction === 'left' && this.positionSlider > 0) {
                this.positionSlider--;
            }

            const element = document.getElementById('review' + this.positionSlider);
            const elementMobile = document.getElementById('reviewMobile' + this.positionSlider);

            if (element || elementMobile) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start'
                });

                elementMobile.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start'
                });
            }
        },
        onSwipe(direction) {
            if (direction === "left" || direction === "right") {
                this.move(direction);
            }
        }
    },
    created() {
        this.fetch();
    }
}
</script>

<style scoped lang="scss">

</style>
